/**{*/
/*  outline: 1px dashed #F002;*/
/*}*/

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58a-wjwxUD2GFw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXp-p7K4KLg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNNepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d3e0O5jH1rrZCu20YNKu4OPFu02G5.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrEVIT9d0c8.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Kurale";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/kurale/v11/4iCs6KV9e9dXjhoKfw72nU6AFw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Tenor";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/tenorsans/v19/bx6ANxqUneKx06UkIXISn3V4Ck-L2qM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#landing {
  position: relative;
  background-image: radial-gradient(#d9d9d9 2px, transparent 2px),
    radial-gradient(#d9d9d9 2px, #ededed 2px);
  background-size: 80px 80px;
  background-position: 0 0, 40px 40px;
}

img {
  object-fit: cover;
}

label[for="audible_legend"] > img {
  width: 32vw !important;
  height: 32vw !important;
}

label[for="audible_legend"] {
  width: 32vw !important;
  height: 32vw !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#nav {
  background-color: rgb(0, 0, 0);
  color: white;
  display: flex;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
}
#nav a > span,
#nav {
  color: #999 !important;
}
#nav a {
  margin-right: 10px;
  font-weight: bold;
}
#nav a.active > span {
  color: white !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  width: 300px;
  background-color: unset !important;
  box-shadow: unset !important;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: unset !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#hero {
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

#heros {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

#hero > img {
  max-height: 70vh;
  max-width: 100vw;
}

#plans {
  margin-top: 150px;
}

#plans,
#slides {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  margin-top: 0;
}

#preview_container {
  min-height: 100vh;
  background: linear-gradient(to right, #064c5a, #047d95);
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px;
}

#preview_container > p {
  max-width: 600px;
}

#footer {
  background: linear-gradient(to right, #064c5a, #047d95);
  color: white;
  width: 100vw;
  height: 100vh;
  margin-top: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

#footer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

#footer > div > img {
  width: 40px;
  margin-bottom: 0;
}

#footer > div > a {
  color: #ffffff;
}

#footer > div > p {
  color: #ffffff;
  margin: unset;
}

.lh-gauge {
  margin: auto;
  stroke-linecap: round;
  width: 120px;
  height: 120px;
}

.lh-gauge-base {
}

.lh-gauge-arc {
  fill: none;
  transform-origin: 50% 50%;
}

.lh-gauge-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  color: #047d95;
  fill: #e1fff7;
  stroke: #047d95;
}

.lh-gauge_percentage {
  display: block;
  z-index: 4;
  width: 100%;
  height: 96px;
  position: absolute;
  font-family: monospace;
  font-size: calc(96px * 0.34 + 1.3px);
  line-height: 0;
  text-align: center;
  top: 63px;
}

.lh-gauge_label {
  font-size: large;
  line-height: 4%;
  margin-top: 30px;
  text-align: center;
  word-break: keep-all;
  color: #047d95;
}

.info {
  display: flex;
  flex-direction: column;
  white-space: normal;
  vertical-align: top;
  scroll-snap-align: center;
  align-items: center;
  border-radius: 20px;
  position: "relative";
  cursor: pointer;
  text-align: center;
  border-radius: 2rem;
  border-width: 2;
  border-style: solid;
  border-color: red;
}

.info-content {
  position: relative;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  padding: 2vw;
  gap: 10px;
}

.info.sm .info-content,
.info.md .info-content,
.info.lg .info-content {
  width: unset;
}

.options {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.info.light {
  background-color: #eee;
}

.info.dark {
  background-color: #666;
}

.info.sm,
.info.sm label {
  width: 16vw !important;
}

.info.sm img {
  width: calc(16vw - 4px) !important;
}

.info textarea {
  margin: 2rem !important;
}

.info.sm textarea {
  width: 14vw !important;
}

.info.sm img,
.info.sm label {
  height: 12vw !important;
}

.info.md,
.info.md label {
  width: 21vw !important;
}

.info.md img {
  width: calc(21vw - 4px) !important;
}

.info.md textarea {
  width: 19vw !important;
}

.info.md img,
.info.md label {
  height: 15vw !important;
}

.info.lg,
.info.lg label {
  width: 33vw !important;
}

.info.lg img {
  width: calc(33vw - 4px) !important;
}

.info.lg img,
.info.lg label,
.info.xl img,
.info.xl label {
  height: 21vw !important;
}

.info.lg textarea {
  width: 21vw !important;
}

.info.xl img,
.info.xl label {
  width: 50vw !important;
}

.info.xl textarea {
  width: calc(50vw - 12rem) !important;
}

.info.xl.row-reverse {
  flex-direction: row-reverse;
}

.info.xl.row {
  flex-direction: row;
}

.section {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
  padding: 2rem;
  border-width: 1;
  border-radius: 2rem;
  border-style: solid;
  border-color: #1677ff;
  background: #ccc;
}

.space.sm {
  height: 2rem;
}

.space.md {
  height: 4rem;
}
.space.lg {
  height: 6rem;
}
.space.xl {
  height: 8rem;
}

.paragraph {
  margin-top: 40px;
  width: calc(100vw - 100px) !important;
  min-height: 20vw;
  border-radius: 20px;
}

.info-container {
  background: #ccc;
  border-radius: 20px;
  flex-wrap: wrap;
  padding: 28px;
  gap: 28px;
}

.infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: 20px;
  margin: auto;
  margin-top: 100px;
  width: 50vw;
}

.infos:nth-child(even) {
  flex-direction: row-reverse;
}

.infos > img {
  width: 300px;
}

.infos > div {
  width: 500px;
}

.marker {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.marker > p {
  color: black;
  background-color: #aaa;
  box-shadow: 0 0 2px 0 black;
  border-radius: 8px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  cursor: pointer;
  font-size: x-small;
}

.marker.active > p {
  background-color: #fff;
}

.marker.left::after {
  content: "";
  display: block;
  width: 2px;
  height: 2vw;
  margin-left: 1vw;
  background-color: #ddd;
  align-self: flex-start;
}

.marker.right::after {
  content: "";
  display: block;
  width: 2px;
  height: 2vw;
  margin-right: 1vw;
  background-color: #ddd;
  align-self: flex-end;
}

#demo_preview {
  width: 800px;
}

@media (max-width: 1279px) {
  #nav a > span:last-child {
    display: none;
  }
}

@media (max-width: 868px) {
  .infos {
    flex-direction: column;
    gap: unset;
    margin-top: 100px;
    width: 90vw;
  }

  .infos:nth-child(even) {
    flex-direction: column;
  }

  .infos > img {
    width: 300px;
  }

  .infos > div {
    width: unset;
    text-align: center;
  }

  .info-container {
    padding: 14px;
    gap: 14px;
  }

  .live-link {
    display: none;
  }

  .paragraph {
    margin-top: 40px;
    width: calc(100vw - 56px) !important;
    min-height: 30vw;
    border-radius: 20px;
  }

  .info.xl.row-reverse,
  .info.xl.row {
    flex-direction: column;
  }

  #plans,
  #slides {
    flex-direction: column;
    margin-bottom: unset;
  }

  #footer {
    flex-direction: column;
    padding: 20px;
    margin-top: 100px;
  }

  #footer > div {
    max-width: unset;
  }

  [data-amplify-authenticator] [data-amplify-router] {
    position: unset;
    margin: 40px;
    margin-bottom: 100px;
  }

  .lh-gauge_percentage {
    height: 96px;
    font-size: calc(60px * 0.34 + 1.3px);
    top: 32px;
  }

  .lh-gauge {
    width: 60px;
    height: 60px;
  }

  .lh-gauge_label {
    font-size: unset;
    margin-top: 30px;
  }

  #plans {
    display: block;
    overflow: scroll;
    white-space: nowrap;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  #plans::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  #plans > div {
    display: inline-block;
  }

  .section {
    margin: 0;
    padding: 2rem;
    border-width: 2px;
  }

  .info.sm,
  .info.md,
  .info.lg,
  .info.xl {
    width: unset !important;
  }

  .info.sm img,
  .info.sm label,
  .info.md img,
  .info.md label,
  .info.lg img,
  .info.lg label,
  .info.xl img,
  .info.xl label {
    width: calc(100vw - 4rem) !important;
    height: 50vw !important;
  }

  .info.sm textarea,
  .info.md textarea,
  .info.lg textarea,
  .info.xl textarea {
    width: calc(100vw - 6rem) !important;
    margin: 1rem !important;
    min-height: 200px;
  }

  #demo_preview {
    width: 90vw;
  }

  #preview_container {
    padding: 40px;
  }
}
