.faq-container {
  }
  
  .faq-item {
    border-bottom: 1px solid #dddddd66;
    padding: 10px 0;
  }
  
  .faq-question {
    font-weight: bold;
    cursor: pointer;
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .faq-answer.active {
    max-height: 200px; /* Adjust according to your content size */
  }
  